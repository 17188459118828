<template>
    <div ref="searchandzoom" :style="searchandzoomStyle" class="searchandzoom">
        <b-card class="medium">
            <b-row>
                <h6 class="pl-3 pt-3">Step 1:</h6>
                <custom-floating-label
                    class="pl-3"
                    :config="{
                        label: 'Select Subdivision',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <select
                        v-model="subdivision"
                        @change="getLines"
                        class="noBorder"
                    >
                        <option
                            v-for="(subdiv, index) in subdivisions"
                            :key="index"
                            :value="subdiv"
                        >
                            {{ subdiv }}
                        </option>
                    </select>
                    <!-- <input 
                v-model="subdivision"
              > -->
                </custom-floating-label>
            </b-row>
            <b-row>
                <h6 class="pl-3 pt-3">Step 2:</h6>
                <custom-floating-label
                    class="pl-3"
                    :config="{
                        label: 'Select LineID',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <select
                        v-model="line"
                        @change="getMileposts"
                        class="noBorder"
                    >
                        <option
                            v-for="(ln, index) in lines"
                            :key="index"
                            :value="ln"
                        >
                            {{ ln }}
                        </option>
                    </select>
                </custom-floating-label>
            </b-row>
            <b-row>
                <h6 class="pl-3 pt-3">Step 3:</h6>
                <custom-floating-label
                    class="pl-3"
                    :config="{
                        label: 'Select Mile Post',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <select v-model="milepost" class="noBorder">
                        <option
                            v-for="(mp, index) in mileposts"
                            :key="index"
                            :value="mp"
                        >
                            {{ mp }}
                        </option>
                    </select>
                </custom-floating-label>
            </b-row>
            <b-row class="d-flex justify-content-center">
                <button class="btn-gray" @click="getMilepostAttributes">
                    Search
                </button>
                <button class="btn-gray" @click="clearMilepostHighlight">
                    Clear
                </button>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import CustomFloatingLabel from '../CustomFloatingLabel.vue';
import { spatialService } from '../../services/spatial.services';
import { spatialFunctions } from '../../utilities/spatialFunctions';
export default {
    props: {},
    data() {
        return {
            width: 400,
            subdivisions: [],
            lines: [],
            mileposts: [],
            subdivision: '',
            line: '',
            milepost: '',
            featureAttributes: {},

            highlightColour: '#d2ff20',
        };
    },
    components: {
        CustomFloatingLabel,
    },
    methods: {
        getLines() {
            spatialService
                .queryLookup(this.projectID, [this.subdivision])
                .then((res) => {
                    this.lines = res.data.map(
                        (a) => a[Object.keys(res.data[0])[0]]
                    );
                    this.line = this.lines[0];
                    this.getMileposts();
                });
        },
        getMileposts() {
            spatialService
                .queryLookup(this.projectID, [this.subdivision, this.line])
                .then((res) => {
                    this.mileposts = res.data.map(
                        (a) => a[Object.keys(res.data[0])[0]]
                    );
                    this.milepost = this.mileposts[0];
                });
        },
        getMilepostAttributes() {
            spatialService
                .queryLookup(this.projectID, [
                    this.subdivision,
                    this.line,
                    this.milepost,
                ])
                .then((res) => {
                    this.featureAttributes = res.data[0];
                    const geometry = this.featureAttributes.Geometry.substring(
                        7,
                        this.featureAttributes.Geometry.length - 1
                    );
                    const [y, x] = geometry.split(' ');
                    const lng = spatialFunctions.meters2lng(y);
                    const lat = spatialFunctions.meters2lat(x);
                    this.$parent.$parent.drawMarkers.push({
                        // using letters and the count to create unique values between different object arrays
                        //    since point 1 and line 1 would be overlapping and throw lots of warnings in the console
                        //    but not errors that will cause issues in the actual execution/functionality
                        id: 'MP' + this.$parent.$parent.drawMarkers.length,
                        coordinates: [lat, lng],
                        color: this.highlightColour,
                        fillOpacity: 1,
                        radius: 5,
                        type: 'Point',
                    });
                    this.$parent.$parent.$refs.lmap.mapObject.fitBounds(
                        [lat, lng],
                        16
                    );
                });
        },
        clearMilepostHighlight() {
            this.$parent.$parent.drawMarkers = this.$parent.$parent.drawMarkers.filter(
                (feature) => !feature.id.startsWith('MP')
            );
        },
    },
    computed: {
        ...mapState('emergencyManagement', ['projectID']),
        searchandzoomStyle: function() {
            return {
                position: 'absolute',
                zIndex: 9999,
                top: '274px',
                width: this.width + 'px',
                left: '64px', //doesn't need to update MidX Position, will always sit px from left
            };
        },
    },
    mounted() {
        spatialService.queryLookup(this.projectID, []).then((res) => {
            if (res.data == 'no query result') {
                this.$emit('noQuery');
            } else {
                this.subdivisions = res.data.map(
                    (a) => a[Object.keys(res.data[0])[0]]
                );
                this.subdivision = this.subdivisions[0];
                this.getLines();
            }
        });
    },
};
</script>
<style scoped>
.searchandzoom {
    border-radius: 4px;
    background-color: white;
    border: 1px solid darkgray;
}
.btn-gray {
    background-color: gray !important;
    color: white;
    height: 42px;
    border-radius: 4px;
    border: none;
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 10px;
    padding-right: 10px;
}
.noBorder {
    border: none;
}
</style>
